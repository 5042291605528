import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import {
  IMediaElement,
  VgApiService,
  VgCoreModule,
} from '@videogular/ngx-videogular/core';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';

import { ActivatedRoute } from '@angular/router';
import { CommonApiService } from '@services/common/common-api.service';
import { CommonModule } from '@angular/common';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VideoData } from '@models/media/videoData.model';

@Component({
  selector: 'app-video-player',
  standalone: true,
  imports: [
    CommonModule,
    NgxSpinnerModule,
    VgBufferingModule,
    VgControlsModule,
    VgCoreModule,
    VgOverlayPlayModule,
  ],
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
  providers: [
    CommonApiService,
    {
      provide: 'API_SERVICE',
      useValue: '',
    },
  ],
})
export class PlayerComponent implements OnInit {
  @ViewChild('media') media!: IMediaElement;

  api!: VgApiService;
  duration!: number;
  currentTime!: number;
  percentageWatched = 0;
  userId: string;
  videoId: string;
  logo: string | null = '';
  firstName: string | null = '';
  videoSource = '';
  ctaTitle? = '';
  ctaAction? = '';

  constructor(
    private service: CommonApiService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.userId = this.route.snapshot.paramMap.get('userId')!;
    this.videoId = this.route.snapshot.paramMap.get('videoId')!;
    this.getData();
  }

  getData() {
    const body = {
      userId: this.userId,
      action: 'OPEN',
    };

    this.service
      .post<VideoData>(`prospects/videos/${this.videoId}/statistics`, body)
      .subscribe(r => {
        this.logo = r.user!.communities[0]!.logoMedia!.url;
        this.firstName = r.user!.firstName;
        this.videoSource = r.prospectVideo!.media.url!;
        this.ctaTitle = r.prospectVideo?.prospectUsers[0].parameters.title;
        this.ctaAction = r.prospectVideo?.prospectUsers[0].parameters.ctaAction;
      });
  }

  onPlayerReady(api: VgApiService) {
    this.api = api;

    if (localStorage.getItem('localStop')) {
      this.api.getDefaultMedia().currentTime = Number(
        localStorage.getItem('localStop')
      );
    }

    if (localStorage.getItem('localPercent')) {
      setTimeout(() => {
        this.percentageWatched = Number(localStorage.getItem('localPercent'));
      });
    }

    this.api
      .getDefaultMedia()
      .subscriptions.loadedMetadata.subscribe(this.onMetadataLoaded.bind(this));
    +this.api.getDefaultMedia().subscriptions.canPlayThrough.subscribe(() => {
      // You may need to wait for the video to load before attaching the events
      this.attachEvents();
    });

    this.api
      .getDefaultMedia()
      .subscriptions.play.subscribe(this.onPlay.bind(this));
    this.api
      .getDefaultMedia()
      .subscriptions.pause.subscribe(this.onPause.bind(this));
    // You can also listen to 'ended' event if you want to handle the video end.
    this.api
      .getDefaultMedia()
      .subscriptions.ended.subscribe(this.onStop.bind(this));
  }

  attachEvents() {
    this.api.getDefaultMedia().subscriptions.timeUpdate.subscribe(() => {
      // Optionally, you can add logic to check if the current time exceeds your allowedSeekTime
    });
  }

  onSeeking(event: any) {
    if (event.type === 'timeUpdate') {
      const currentTime = event.target.currentTime;
      if (currentTime > this.currentTime) {
        // If current time exceeds the allowed seek time, set the current time to the allowed seek time
        this.api.getDefaultMedia().currentTime = this.currentTime;
      }
    }
  }

  onMetadataLoaded() {
    this.duration = this.api.getDefaultMedia().duration;
  }

  onUpdateTime(event: any) {
    this.currentTime = event.target.currentTime;
    if (this.duration && this.currentTime) {
      this.percentageWatched = Math.round(
        (this.currentTime / this.duration) * 100
      );
    }
  }

  onPlay() {
    const body = {
      userId: this.userId,
      action: 'PLAY',
      positionInSec: this.api.getDefaultMedia().currentTime,
      percentage: this.percentageWatched,
    };

    this.service
      .post<VideoData>(`prospects/videos/${this.videoId}/statistics`, body)
      .subscribe();
  }
  onPause() {
    const body = {
      userId: this.userId,
      action: 'PAUSE',
      positionInSec: this.api.getDefaultMedia().currentTime,
      percentage: this.percentageWatched,
    };

    if (this.percentageWatched < 100) {
      this.service
        .post<VideoData>(`prospects/videos/${this.videoId}/statistics`, body)
        .subscribe();
    }
  }

  onStop() {
    localStorage.setItem(
      'localStop',
      this.api.getDefaultMedia().currentTime.toString()
    );
    localStorage.setItem('localPercent', this.percentageWatched.toString());
    // Do something when the video pauses

    const body = {
      userId: this.userId,
      action: 'END',
      positionInSec: this.api.getDefaultMedia().currentTime,
      percentage: this.percentageWatched,
    };

    this.service
      .post<VideoData>(`prospects/videos/${this.videoId}/statistics`, body)
      .subscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification() {
    this.onStop();
  }
}
